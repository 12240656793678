import React, { useState, useEffect, useMemo } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDay } from 'store/trip-schedule'
import { SwiperSlide, Swiper as ReactSwiper } from 'swiper/react'
import { FreeMode, Mousewheel } from 'swiper/modules'
import Swiper from 'swiper'
import { useDebounce } from 'use-debounce'
import { RestBooking } from 'data/types/booking'
import { RootState } from 'store'
import { isServer } from 'data/helpers/ssr'
import classNames from 'classnames'
import { parseDate } from '@ama-selections/ui'

interface UserTripSummaryDayRowListProps {
  booking: RestBooking
}

const UserTripSummaryDayRowList = ({
  booking,
}: UserTripSummaryDayRowListProps) => {
  const dispatch = useDispatch()

  const [days, setDays] = useState<Dayjs[]>([])
  const [swiper, setSwiper] = useState<Swiper | null>(null)

  if (isServer) {return null}

  const { selectedDay } = useSelector((state: RootState) => ({
    selectedDay: state.tripSchedule.selectedDay,
  }))

  useEffect(() => {
    if (!selectedDay) {
      const today = dayjs()
      const arrival = parseDate(booking.arrival)

      // If today is after arrival and before departure, select today
      if (today.isAfter(arrival) && today.isBefore(parseDate(booking.departure))) {
        dispatch(setSelectedDay(today.toString()))
      } else {
        dispatch(setSelectedDay(booking.arrival))
      }
    }

    const arrival = dayjs(booking.arrival)
    const departure = dayjs(booking.departure)
    const diff = departure.diff(arrival, 'days')

    // Add padded, disabled days before and after the trip
    const items: Dayjs[] = Array.from({ length: diff + 8 }, (_, i) => {
      if (i === 0) {
        return dayjs(arrival).subtract(1, 'day')
      } else if (i <= diff + 1) {
        return dayjs(arrival).add(i - 1, 'day')
      } else {
        return dayjs(departure).add(i - diff - 1, 'day')
      }
    })

    setDays(items)
  }, [booking])

  const selectDay = (day: Dayjs) => {
    dispatch(setSelectedDay(day.toString()))
  }

  const [debouncedSelectedDay] = useDebounce(selectedDay, 150)

  useMemo(() => {
    if (swiper) {
      swiper.slideTo(days?.findIndex((day: Dayjs) => dayjs(debouncedSelectedDay).toString() == day.toString()))
    }
  }, [debouncedSelectedDay, days, swiper])

  return (
    <div className="relative lg:max-w-[761px] mx-auto bg-white py-10 mb-8">

      {/* Fade on sides of bar */}
      <div className="absolute left-0 z-10 hidden pointer-events-none lg:block h-80 w-50 bg-gradient-to-r from-white to-transparent" />
      <div className="absolute right-0 z-10 hidden pointer-events-none lg:block h-80 w-50 bg-gradient-to-l from-white to-transparent" />

      <ReactSwiper
        onSwiper={setSwiper}
        initialSlide={1}
        className="flex min-w-0"
        modules={[Mousewheel, FreeMode]}
        centeredSlides={true}
        centeredSlidesBounds={true}
        slidesPerView="auto"
        freeMode={{
          enabled: true,
          sticky: false,
          momentumVelocityRatio: 0.20,
        }}
        spaceBetween={10}
        mousewheel={{
          forceToAxis: true,
        }}
      >
        {days.map((day, index) => {
          const isSelected = dayjs(debouncedSelectedDay).toString() == day.toString()

          return (
            <SwiperSlide key={index} className="w-max">
              <button
                className={classNames(
                  'w-max text-left flex items-top justify-between',
                  'rounded-16 whitespace-nowrap px-16 py-10 flex flex-col',
                  'items-center justify-center min-w-[72px] min-h-[80px]',
                  'disabled:text-grey-250',
                  {
                    'text-white bg-primary-fresh': isSelected,
                    'text-grey-500 bg-grey-750 bg-opacity-0': !isSelected,
                  },
                )}
                onClick={() => selectDay(day)}
                disabled={day.isBefore(dayjs(booking.arrival)) || day.isAfter(dayjs(booking.departure))}
              >
                <p className="font-bold text-13 leading-22">{day.format('ddd')}</p>
                <p className="font-bold text-18 leading-32">{day.format('D')}</p>
              </button>
            </SwiperSlide>
          )
        })}
      </ReactSwiper>
    </div>
  )
}

export default UserTripSummaryDayRowList
