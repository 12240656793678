import { ClockOutlineIcon } from 'components/icons'
import { TripScheduleStatus, isPending } from 'data/enums/trip-schedule/trip-schedule-status'
import { setSelectedService } from 'store/trip-schedule'
import { useDispatch } from 'react-redux'
import RestUserTripServicePill from 'components/booking/trip-schedule/RestUserTripServicePill'
import classNames from 'classnames'
import { getFormattedTime } from 'data/helpers/survey/tripSchedule'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { RestBooking } from 'data/types/booking'
import UserTripServiceImage from 'components/booking/trip-schedule/UserTripServiceImage'
import { getTripScheduleServiceStatusType, getTripScheduleServiceType } from 'data/helpers/trip-schedule-group'

interface TripSummaryViewCardItemProps {
  service: TripScheduleService
  booking: RestBooking
}

const TripSummaryViewCardItem = ({
  service,
  booking,
}: TripSummaryViewCardItemProps) => {
  const dispatch = useDispatch()

  const buttonContainerClassNames = classNames(
    'relative flex flex-col lg:flex-row justify-between gap-12 lg:gap-26 p-12 bg-grey-150',
    {
      'bg-red-50': isPending(service.status),
      'opacity-50': service.status === TripScheduleStatus.CANCELLED || service.status === TripScheduleStatus.DRAFT,
    },
  )

  return (
    <button
      type="button"
      className="xl:max-w-[770px] mx-auto w-full rounded-16 overflow-clip"
      onClick={() => dispatch(setSelectedService(service?.id))}
    >
      <div className={buttonContainerClassNames}>
        <div className="flex gap-12 lg:gap-26 min-h-[65px] w-full">
          <UserTripServiceImage
            image={{
              src: service?.image,
              alt: service?.title,
            }}
            type={getTripScheduleServiceType(service?.type)}
            status={getTripScheduleServiceStatusType(service?.status)}
            className={{
              container: 'rounded-10 overflow-hidden h-[80px] min-w-[80px] max-w-[80px] lg:h-[108px] lg:min-w-[108px] lg:max-w-[108px]',
            }}
          />

          <div className="relative flex flex-col w-full gap-5 md:flex-row">
            <div className="flex flex-col items-start w-full h-full text-12 lg:text-14 leading-22">

              {/* Service Name */}
              <p className="font-semibold font-poppins text-15 leading-22 text-grey-900 tracking-1/4 mb-[6px]">
                {service?.title}
              </p>

              {service?.subtitle && (
                <p className="mb-4 text-grey-800 text-14 leading-22">
                  {service?.subtitle}
                </p>
              )}

              {/* Time */}
              <div className="flex items-center gap-[6px] text-grey-700">
                <ClockOutlineIcon className="h-14 w-14 stroke-[1.5]" />
                <p className="text-14 leading-22 tracking-1/4">
                  {getFormattedTime(
                    service?.start_time,
                    service?.end_time,
                    getTripScheduleServiceType(service?.type),
                  )}
                </p>
              </div>

            </div>
            <div className="flex-col justify-between hidden md:items-end gap-y-10 lg:flex">
              <div className="flex flex-col md:items-end gap-y-5">
                <RestUserTripServicePill
                  service={service}
                  booking={booking}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="block lg:hidden">
          <RestUserTripServicePill
            service={service}
            booking={booking}
          />
        </div>
      </div>
    </button>
  )
}

export default TripSummaryViewCardItem
