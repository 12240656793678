import React from 'react'
import PropTypes from 'prop-types'
import { AddSquare, LoadingIcon } from 'components/icons'
import { Popover } from '@headlessui/react'
import IconButton from './IconButton'
import ProfileImage from 'components/ProfileImage'
import classNames from 'classnames'

const AvatarPopover = ({
  position = 'bottom',
  users = [],
  onClick,
  selectedId,
  label,
  isLoading,
}) => {
  const panelPosition = classNames({
    'bottom-0 right-65': position === 'left',
    'right-0 top-65': position === 'bottom',
  })

  const arrowPosition = classNames({
    'right-[-6px] bottom-20 rotate-[135deg] ': position === 'left',
    'top-[-6px] right-25 rotate-45': position === 'bottom',
  })

  return (
    <>
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button className="rounded-12">
              <IconButton className="w-55">
                {selectedId
                  ? <ProfileImage
                    className="h-25 w-25 min-w-25 min-h-25"
                    textClassName="text-20"
                    user={users.find((e) => e.id === selectedId)}
                    showVerified
                  />
                  : <AddSquare />
                }
              </IconButton>
            </Popover.Button>

            <Popover.Panel className={`absolute z-10 ${panelPosition}`}>
              <div className="relative h-max max-h-[360px] w-[240px] pt-10 flex flex-col text-left border border-primary bg-primary-light rounded-6">
                <div className={`absolute z-20 w-10 h-10 border-t border-l border-primary bg-primary-light ${arrowPosition}`} />

                {label
                  && <p className="mb-10 font-semibold px-15 text-primary">
                    {label}
                  </p>
                }
                <div className="max-h-[300px] overflow-y-auto text-primary text-16">
                  {isLoading
                    ? <div className="flex items-center justify-center p-30 text-grey-800"><LoadingIcon /></div>
                    : users?.map((person, key) =>
                      <button
                        key={key}
                        onClick={() => onClick(person.id)}
                        type="button"
                        className="flex items-center w-full gap-5 py-5 cursor-pointer px-15 hover:bg-primary-fresh hover:bg-opacity-8"
                      >
                        <ProfileImage
                          className="h-30 w-30 min-w-30 max-w-30 max-h-30"
                          textClassName="text-20"
                          user={person}
                          showVerified
                        />

                        <div className={`${person.id === selectedId ? 'font-semibold text-primary' : 'font-normal text-black'} text-left ml-3 text-12`}>
                          {person.first_name} {person.last_name}
                        </div>
                      </button>,
                    )}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  )
}

AvatarPopover.propTypes = {
  position: PropTypes.oneOf(['left', 'bottom']),
  users: PropTypes.array,
  onClick: PropTypes.func,
  selectedId: PropTypes.number,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default AvatarPopover
