import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import Button, { ButtonProps } from 'components/home/common/Button'
import { AppleIcon, FacebookIcon, GoogleStyledIcon } from 'components/icons'
import ApiClient from 'data/api/api_client'
import queryCache from 'data/queryCache'
import { ReactChild } from 'data/types/types'
import { FormEventHandler } from 'react'
import { useQuery } from 'react-query'

export enum AuthSectionPanelSsoText {
  Register = 'Sign up with',
  Login = 'Sign in with',
  Link = 'Link and Sign in with',
}

export enum LoginMethod {
  APPLE = 'apple',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  EMAIL = 'email',
}

export interface AuthSectionPanelProps {
  children: ReactChild
  button?: Omit<ButtonProps, 'children'> & {
    text: string
    children?: ReactChild
  }
  socialLogin?: {
    show: boolean | LoginMethod[]
    text: AuthSectionPanelSsoText
  }
  onSubmit?: FormEventHandler<HTMLFormElement>
  buttonStyle: ButtonProps['style']
  stickyFooter?: boolean
}

export default function AuthSectionPanel ({
  children,
  button,
  socialLogin,
  onSubmit,
  buttonStyle,
  stickyFooter = true,
}: AuthSectionPanelProps) {
  const { data: socialLinks } = useQuery(
    queryCache.socialLinks,
    () => ApiClient.auth.getSocialLinks(),
    {
      enabled: !!socialLogin?.show,
    },
  )

  return (
    <Tab.Panel
      {
        ...(onSubmit
          ? {
            as: 'form',
            onSubmit: onSubmit,
          }
          : {
            as: 'div',
          })
      }
      className="flex flex-col flex-grow gap-20 focus:outline-none"
      tabIndex={-1}
    >
      {children}

      <div className={classNames(
        'flex flex-col gap-20',
        {
          'mt-auto': stickyFooter,
          'mt-20': !stickyFooter,
        },
      )}
      >
        {
          socialLogin?.show && socialLinks && (
            <>
              {(socialLogin.show === true || socialLogin.show.includes(LoginMethod.APPLE)) && (
                <Button
                  href={socialLinks.apple}
                  variant="sso_login"
                  style={buttonStyle}
                  icon={<AppleIcon className="text-20" />}
                >
                  {socialLogin.text} Apple
                </Button>
              )}

              {(socialLogin.show === true || socialLogin.show.includes(LoginMethod.FACEBOOK)) && (
                <Button
                  href={socialLinks.facebook}
                  variant="sso_login"
                  style={buttonStyle}
                  icon={<FacebookIcon className="text-20 text-[#1877F2]" />}
                >
                  {socialLogin.text} Facebook
                </Button>
              )}

              {(socialLogin.show === true || socialLogin.show.includes(LoginMethod.GOOGLE)) && (
                <Button
                  href={socialLinks.google}
                  variant="sso_login"
                  style={buttonStyle}
                  icon={<GoogleStyledIcon className="text-20" />}
                >
                  {socialLogin.text} Google
                </Button>
              )}
            </>
          )
        }

        { button && (
          <>
            <Button
              className={{
                button: 'justify-self-end',
              }}
              style={buttonStyle}
              {...button}
            >
              {button.text}
            </Button>

            {button.children}
          </>
        )}
      </div>
    </Tab.Panel>
  )
}
