import {
  DayPickerSingleDateController,
  DayPickerSingleDateControllerShape,
  toMomentObject,
} from 'react-dates'
import { useMemo } from 'react'
import Pill from 'components/Pill'

export type DateInput = Date | undefined

export type DatePickerProps = {
  disabledDates?: (Date | { before?: Date, after?: Date })[]
  initialMonth?: Date
  minDate?: Date
  value: DateInput
  onChange: (date: Date | undefined) => void
  focused?: DayPickerSingleDateControllerShape['focused']
  onFocusChange?: DayPickerSingleDateControllerShape['onFocusChange']
} & Omit<
  DayPickerSingleDateControllerShape,
  | 'onDateChange'
  | 'date'
  | 'focused'
  | 'onFocusChange'
  | 'initialVisibleMonth'
  | 'minDate'
  | 'renderMonthElement'
>

const DatePicker = ({
  disabledDates = [],
  initialMonth = new Date(),
  minDate = new Date(),
  value,
  onChange,
  focused = true,
  onFocusChange = () => {},
  ...props
}: DatePickerProps) => {
  const minDateMomentObject = useMemo(() => toMomentObject(minDate)!, [minDate])
  const initialMonthMomentObject = useMemo(() => toMomentObject(initialMonth)!, [initialMonth])

  return (
    <div className="flex flex-col h-full">
      <DayPickerSingleDateController
        onDateChange={(date) => {
          onChange(date ? date.toDate() : undefined)
        }}
        focused={focused}
        onFocusChange={onFocusChange}
        date={toMomentObject(value)}
        orientation="verticalScrollable"
        hideKeyboardShortcutsPanel
        numberOfMonths={6}
        navNext={
          <div className="flex justify-center py-3">
            <Pill
              text="Load More"
              variant="gold"
              className="hover:bg-primary hover:bg-opacity-30"
            />
          </div>
        }
        initialVisibleMonth={() => initialMonthMomentObject}
        minDate={minDateMomentObject}
        noNavPrevButton={minDateMomentObject.isSameOrAfter(initialMonthMomentObject)}
        navPrev={
          <div className="flex justify-center py-3">
            <Pill
              text="Load Previous"
              variant="gold"
              className="hover:bg-primary hover:bg-opacity-30"
            />
          </div>
        }
        verticalBorderSpacing={3}
        isDayBlocked={(day) => {
          let isBlocked = false

          disabledDates.forEach(disabledDate => {
            if (disabledDate instanceof Date) {
              if (day.isSame(disabledDate, 'day')) {
                isBlocked = true
                return
              }
            } else {
              if (disabledDate.after  && disabledDate.before) {
                if (day.isBetween(disabledDate.after, disabledDate.before, 'day', '()')) {
                  isBlocked = true
                  return
                }
              } else if (disabledDate.after && !disabledDate.before) {
                if (day.isAfter(disabledDate.after, 'day')) {
                  isBlocked = true
                  return
                }
              } else if (!disabledDate.after && disabledDate.before) {
                if (day.isBefore(disabledDate.before, 'day')) {
                  isBlocked = true
                  return
                }
              }
            }
          })

          return isBlocked
        }}
        isOutsideRange={(day) => day.isBefore(minDateMomentObject)}
        noBorder
        {...props}
      />
    </div>
  )
}

export default DatePicker
