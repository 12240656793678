import ApiClient from 'data/api/api_client'
import placeholderImage from 'assets/images/placeholder.png'
import DetailedBookingSidebar from 'components/DetailedBookingSidebar'
import { handleServerSideErrors } from 'data/helpers/errors'
import BookingAccessProvider from 'providers/BookingAccessProvider'
import BookingGuestsTrigger from 'components/modals/BookingGuestsTrigger'
import { GetServerSidePropsContext } from 'next'
import PublicLayout, { PublicLayoutProps, PublicLayoutVariant } from 'layouts/PublicLayout'
import BookingMenuModal from 'components/home/BookingMenuModal'
import UserDashboardContent, { UserDashboardContentProps } from 'layouts/UserDashboard/UserDashboardContent'
import { RestBooking } from 'data/types/booking'
import TabGroup, { TabGroupProps } from 'components/home/common/TabGroup'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'data/hooks/useSearchParams'
import { useRouterWithQuery } from 'data/hooks/useRouterWithQuery'
import SummaryViewTab from 'components/trip-schedule/tabs/SummaryViewTab'
import UserServiceRequestModal from 'components/modals/UserServiceRequestModal'
import UserTripServiceModal from 'components/booking/trip-schedule/UserTripServiceModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setPendingServicesModalOpen, setSelectedService, setSelectedServiceRequest } from 'store/trip-schedule'
import CalendarViewTab from 'components/trip-schedule/tabs/CalendarViewTab'
import Button from 'components/home/common/Button'
import { AddIcon, ShareArrowIcon } from 'components/icons'
import UserPendingTripServicesModal from 'components/booking/trip-schedule/pending/UserPendingTripServicesModal'
import { TripScheduleServiceStatusType } from 'gql/graphql'
import UserTripServiceShareModal from 'components/booking/trip-schedule/UserTripServiceShareModal'
import { MobileScreenWidths, useScreenWidth } from 'data/hooks/useScreenWidth'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { CommonParams } from 'data/enums/params'
import InvitedGuestModal from 'components/modals/bookings/shared/InvitedGuestsModal'
import BookNewTripServiceTrigger from 'components/trip-schedule/BookNewTripServiceTrigger'
import { asDate } from '@ama-selections/ui/src/helpers/dates'
import { useQuery } from 'react-query'
import ServiceSpecificSearchModal from 'components/modals/home/search/services/ServiceSpecificSearchModal'

interface UserBookingTripSchedulePageProps {
  initialData: {
    booking: RestBooking
    tripServices: TripScheduleService[]
  }
}

export enum ItineraryTab {
  DayView = 'day',
  CalendarView = 'calendar',
}

const UserBookingTripSchedulePage = ({
  initialData,
}: UserBookingTripSchedulePageProps) => {
  const { booking } = initialData ?? {}
  const { params } = useSearchParams()
  const { updateQueryParam } = useRouterWithQuery()
  const dispatch = useDispatch()
  const { isMobile } = useScreenWidth(MobileScreenWidths.LG)

  if (!booking) {
    return null
  }

  const { data: tripServices } = useQuery(
    ['tripScheduleGroup', booking.id],
    () => ApiClient.tripSchedule.getTripScheduleServices(booking.id, {
      include_payment_statuses: true,
      include_service_survey: true,
    }),
    {
      initialData: initialData?.tripServices,
    },
  )

  const {
    selectedService,
    selectedServiceRequest,
    pendingServicesModalOpen,
    selectedDay,
  } = useSelector((state: RootState) => ({
    selectedService: state.tripSchedule.selectedService,
    selectedServiceRequest: state.tripSchedule.selectedServiceRequest,
    pendingServicesModalOpen: state.tripSchedule.pendingServicesModalOpen,
    selectedDay: state.tripSchedule.selectedDay,
  }))

  const itineraryFilters: TabGroupProps['items'] = [
    { label: 'Day View', value: ItineraryTab.DayView },
    { label: 'Calendar View', value: ItineraryTab.CalendarView },
  ]

  const [selectedFilter, setSelectedFilter] = useState<TabGroupProps['items'][number] | null>(null)

  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [initialPendingTab, setInitialPendingTab] = useState(TripScheduleServiceStatusType.PendingApproval)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false)

  useEffect(() => {
    if (params.get('service')) {
      dispatch(setSelectedService(params.get('service')))
    }

    if (params.get('pending')) {
      switch (params.get('pending')) {
        case 'payment':
          setInitialPendingTab(TripScheduleServiceStatusType.PendingPayment)
          dispatch(setPendingServicesModalOpen(true))
          break
        case 'approval':
          setInitialPendingTab(TripScheduleServiceStatusType.PendingApproval)
          dispatch(setPendingServicesModalOpen(true))
          break
        case 'sharetrip':
          setIsShareModalOpen(true)
          break
        case 'guest':
          setShareModalOpen(true)
          break
        case 'service':
          setIsServiceModalOpen(true)
          break
      }
    }
  }, [params.get('pending'), params.get('service')])

  useEffect(() => {
    if (selectedFilter) {
      updateQueryParam('tab', selectedFilter.value)
    }
  }, [selectedFilter])

  const publicLayoutProps: Omit<PublicLayoutProps, 'children'> = {
    variant: PublicLayoutVariant.Home,
    className: {
      content: 'h-[calc(100vh-166px-60px)] lg!h-screen', // Ensure only the itinerary is scrollable on mobile
    },
    mobileNavBar: {
      visible: true,
      back: true,
      fade: true,
      share: {
        onShare: () => setShareModalOpen(true),
      },
      className: {
        image: {
          carousel: 'h-top-safe-offset-[166px]',
          overlay: 'absolute w-max bottom-0 z-10 h-max bottom-[13px] right-[22px]',
        },
      },
      title: <BookingMenuModal booking={booking} />,
      images: [{
        url: booking?.property.main_image ?? placeholderImage.src,
        alt_text: booking?.property.main_image_alt_text,
      }],
      imageOverlay: (
        <div className="relative flex items-end justify-end h-full pr-[22px] pb-[13px]">
          <BookingGuestsTrigger
            booking={booking}
            showLabel={false}
            size="sm"
          />
        </div>
      ),
    },
  }

  const userDashboardContentProps: Omit<UserDashboardContentProps, 'children'> = {
    sidebar: (
      <DetailedBookingSidebar booking={booking} bookingNumber={booking?.id}>
        <div className="flex flex-col gap-[7px]">
          <Button
            onClick={() => dispatch(setPendingServicesModalOpen(true))}
            variant="secondary"
            style="modal"
            className={{ button: '!text-12 !py-12 whitespace-nowrap' }}
            block
          >
            Approve Pending Services
          </Button>
          <Button
            onClick={() => setIsShareModalOpen(true)}
            variant={booking?.booking_information?.length > 0 ? 'secondary' : 'primary'}
            style="modal"
            className={{ button: '!text-12 !py-12' }}
            block
            icon={<ShareArrowIcon />}
          >
            Share Trip Itinerary
          </Button>
        </div>
      </DetailedBookingSidebar>
    ),
    title: {
      regular: 'Stay at',
      italic: booking.property.title,
      leftElement: <TabGroup.URLControlled
        items={itineraryFilters}
        onChange={setSelectedFilter}
        name="tab"
      />,
      rightElement: <BookingGuestsTrigger
        booking={booking}
        showLabel={false}
        className={{
          container: 'justify-end',
        }}
        size="sm"
      />,
    },
    className: {
      container: 'lg:pt-0 lg:!mt-0 !px-0 !h-[calc(100vh-226px)] lg:!h-[calc(100%-70px)]',
      content: 'relative !flex-grow-0 overflow-y-auto !mb-safe',
    },
  }

  useEffect(() => {
    if (isMobile !== undefined) {
      setSelectedFilter(itineraryFilters.find((filter) => filter.value === params.get(CommonParams.TAB)) ?? itineraryFilters[isMobile ? 0 : 1])
    }
  }, [isMobile, params.get(CommonParams.TAB)])

  if (!selectedFilter) {
    return null
  }

  return (
    <PublicLayout {...publicLayoutProps}>
      <BookingAccessProvider
        booking={booking}
        requiresFullAccess={false}
      >
        <UserDashboardContent {...userDashboardContentProps}>

          {isMobile
            ? <TabGroup.URLControlled
              items={itineraryFilters}
              onChange={setSelectedFilter}
              className={{
                container: 'py-10 sticky top-0 z-10 w-full bg-white justify-center flex',
              }}
              name="tab"
            />
            : null
          }

          {selectedFilter.value === ItineraryTab.DayView
            ? <SummaryViewTab
              booking={booking}
              tripServices={tripServices ?? []}
            />
            : null
          }

          {selectedFilter.value === ItineraryTab.CalendarView
            ? <CalendarViewTab
              booking={booking}
              tripServices={tripServices ?? []}
            />
            : null
          }

          <UserServiceRequestModal
            isOpen={!!selectedServiceRequest}
            onClose={() => dispatch(setSelectedServiceRequest(null))}
            serviceRequestId={selectedServiceRequest}
          />

          <UserTripServiceModal
            isOpen={!!selectedService}
            onClose={() => dispatch(setSelectedService(null))}
            serviceId={selectedService}
          />

          {selectedFilter.value === ItineraryTab.DayView || isMobile
            ? (
              <BookNewTripServiceTrigger
                booking={booking}
                date={asDate(selectedDay)}
              >
                {({ open }) => (
                  <div className="fixed bottom-safe-offset-30 mb-40 lg:mt-0 mt-auto lg:bottom-[44px] px-24 lg:px-0 z-10 flex justify-end w-full lg:justify-center pointer-events-none">
                    <Button
                      className={{
                        button: '!rounded-full !p-16 pointer-events-auto',
                      }}
                      onClick={open}
                    >
                      <AddIcon className="w-16 h-16" />
                    </Button>
                  </div>
                )}
              </BookNewTripServiceTrigger>
            )
            : null
          }

          <ServiceSpecificSearchModal
            booking={booking}
            isOpen={isServiceModalOpen}
            onClose={() => setIsServiceModalOpen(false)}
            className={{
              panel: '!max-w-[850px]',
            }}
          />
        </UserDashboardContent>
      </BookingAccessProvider>

      <UserPendingTripServicesModal
        isOpen={!!pendingServicesModalOpen}
        onClose={() => dispatch(setPendingServicesModalOpen(false))}
        bookingId={booking.id.toString()}
        initialTab={initialPendingTab}
      />

      <UserTripServiceShareModal
        booking={booking}
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />

      <InvitedGuestModal
        booking={booking}
        isOpen={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
      />
    </PublicLayout>
  )
}

export async function getServerSideProps ({ req, query, resolvedUrl }: GetServerSidePropsContext) {
  try {
    const [booking, tripServices] = await Promise.all([
      ApiClient.withReq(req).bookings.getBooking(query.id as string, {
        includes: [
          'property', 'property.media', 'property.housekeepingServices', 'property.linenTowelsServices', 'property.staffServices', 'property.otherServices', 'property.propertyHouseRules', 'property.destinationRegion',
          'sharedUsers', 'sharedUserInvites',
          'servicePayments', 'servicePayments.tripSchedule', 'servicePayments.parts', 'servicePayments.parts.payment', 'servicePayments.parts.payment.paymentGroup',
          'client', 'client.address', 'client.userPreferences', 'client.teamContacts', 'client.media', 'client.bookings',
          'bookingAgent', 'bookingAgent.address', 'bookingAgent.userPreferences', 'bookingAgent.teamContacts', 'bookingAgent.media', 'bookingAgent.bookings',
          'digitalConcierge', 'digitalConcierge.address', 'digitalConcierge.userPreferences', 'digitalConcierge.teamContacts', 'digitalConcierge.media', 'digitalConcierge.bookings',
          'tripScheduleServices', 'bookingPropertyRates', 'conversations', 'client',
        ],
      }),
      ApiClient.tripSchedule.getTripScheduleServices(query.id, {
        include_payment_statuses: true,
        include_service_survey: true,
      }),
    ])

    return {
      props: {
        initialData: {
          booking: booking ?? {},
          tripServices: tripServices ?? [],
        },
      },
    }
  } catch (e) {
    return handleServerSideErrors(e, resolvedUrl, false)
  }
}


export default UserBookingTripSchedulePage
