import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

interface StickyFooterProps {
  children?: ReactChild
  className?: string
}

const StickyFooter = ({
  children,
  className,
}: StickyFooterProps) => {
  return (
    <div className={classNames(
      'sticky bottom-0 z-10 flex flex-col items-center px-24 pt-10 -mx-24 bg-white pb-safe-or-10 mt-auto',
      className,
    )}>
      {children}
    </div>
  )
}

export { StickyFooter }
