import ServiceSpecificSearchModal from 'components/modals/home/search/services/ServiceSpecificSearchModal'
import PublicMobileModal from 'components/PublicMobileModal'
import Button from 'components/home/common/Button'
import { RestBooking } from 'data/types/booking'
import React, { useState } from 'react'
import UserCustomEventModal from 'components/booking/trip-schedule/UserCustomEventModal'

export type BookNewTripServiceTriggerRenderProps = {
  open: () => void
  isOpen: boolean
}

interface BookNewTripServiceTriggerProps {
  booking: RestBooking
  date?: Date
  children: (props: BookNewTripServiceTriggerRenderProps) => React.ReactNode
}

const BookNewTripServiceTrigger = ({
  booking,
  date,
  children,
}: BookNewTripServiceTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false)
  const [isUserCustomEventModalOpen, setIsUserCustomEventModalOpen] = useState(false)

  return (
    <>
      {
        children({
          open: () => setIsOpen(true),
          isOpen,
        })
      }

      <PublicMobileModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className={{
          panel: 'max-w-sm !pb-24',
        }}
      >
        <div className="flex flex-col gap-12 px-24 pt-[74px]">
          <Button
            block
            variant="secondary"
            style="modal"
            onClick={() => {
              setIsOpen(false)
              setIsUserCustomEventModalOpen(true)
            }}
          >
            ADD YOUR OWN EVENT
          </Button>

          <Button
            block
            variant="primary"
            style="modal"
            onClick={() => {
              setIsOpen(false)
              setIsServiceModalOpen(true)
            }}
          >
            BOOK A SERVICE
          </Button>
        </div>
      </PublicMobileModal>

      <UserCustomEventModal
        isOpen={isUserCustomEventModalOpen}
        onClose={() => setIsUserCustomEventModalOpen(false)}
        booking={booking}
        initialDate={date}
      />

      <ServiceSpecificSearchModal
        booking={booking}
        isOpen={isServiceModalOpen}
        onClose={() => setIsServiceModalOpen(false)}
        className={{
          panel: '!max-w-[850px]',
        }}
      />
    </>
  )
}

export default BookNewTripServiceTrigger
