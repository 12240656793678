import React from 'react'
import ProfileImage from 'components/ProfileImage'
import classNames from 'classnames'
import { Media, User } from 'gql/graphql'
import { AddCircleDotted } from './icons'
import { ReactChild } from 'data/types/types'

interface ProfileIconsButtonProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'className'> {
  users: {
    id: User['id']
    name?: string
    first_name?: string
    profile_image?: Pick<Media, 'url'> | null
  }[]
  size: 'sm' | 'md' | 'xl'
  maxIcons?: number
  className?: {
    button?: string
    icon?: string
  }
  emptyIcon?: ReactChild
}

const ProfileIconsButton = ({
  users,
  size,
  maxIcons = users.length,
  onClick,
  className,
  emptyIcon = <AddCircleDotted />,
}: ProfileIconsButtonProps) => {
  const iconsToShow = Math.min(maxIcons, users.length)

  const getSuffix = () => {
    if (users.length === 0) {
      return (
        <div className={classNames(
          'text-grey-650 focus:lg:text-primary-fresh -m-10 p-10',
          {
            'text-[28px]': size === 'sm',
            'text-[32px]': size === 'md',
            'text-[38px]': size === 'xl',
          },
          className?.icon,
        )}>
          {emptyIcon}
        </div>
      )
    } else if (users.length <= 3) {
      return (
        <div className={classNames(
          'text-primary-fresh',
          {
            'relative ml-2': iconsToShow,
          },
        )}>
          <AddCircleDotted className={classNames(
            'text-grey-650 focus:lg:text-primary-fresh',
            {
              'text-[28px]': size === 'sm',
              'text-[32px]': size === 'md',
              'text-[38px]': size === 'xl',
            },
          )} />
        </div>
      )
    } else {
      return (
        <div className={classNames(
          'flex items-center justify-center ml-2 bg-grey-250 border-blue-50 border-[1.5px] rounded-full z-[1]',
          {
            'min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px]': size === 'sm',
            'min-w-[32px] max-w-[32px] min-h-[32px] max-h-[32px]': size === 'md',
            'min-w-[38px] max-w-[38px] min-h-[38px] max-h-[38px]': size === 'xl',
          })}
        >
          <p className="font-bold text-grey-800 text-10 leading-20">
            +{users.length - 3}
          </p>
        </div>
      )
    }
  }

  return (
    <div className="flex">
      <button
        className={classNames(
          'flex items-center focus:outline-none group',
          className?.button,
        )}
        onClick={(e) => {
          e.preventDefault()
          onClick?.(e)
        }}
      >
        {users.slice(0, 3).map((_, index) => (
          <ProfileImage
            key={`user-${users[index].id}-${index}`}
            user={users[index]}
            className={classNames(
              'relative border-[1.5px] border-blue-50 mr-[-10px]',
              {
                'mr-[-6px]': index !== (iconsToShow - 1),
                'min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px]': size === 'sm',
                'min-w-[32px] max-w-[32px] min-h-[32px] max-h-[32px]': size === 'md',
                'min-w-[38px] max-w-[38px] min-h-[38px] max-h-[38px]': size === 'xl',
              },
            )}
          />
        ))}

        {getSuffix()}
      </button>
    </div>
  )
}

export default ProfileIconsButton
