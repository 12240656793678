import Button from 'components/home/common/Button'
import useAuth from 'data/hooks/User/useAuth'
import { graphql } from 'gql'
import { useQuery } from 'urql'

const GET_CUSTOMER_PORTAL = graphql(`
  query GetCustomerPortal {
    currentUser {
      id
      customer_portal_session {
        url
      }
    }
  }
`)

const ManageSubscriptionButton = () => {
  const { user } = useAuth()

  const [{ data, fetching }] = useQuery({
    query: GET_CUSTOMER_PORTAL,
  })

  if (fetching || !user) {
    return null
  }

  // Hide button if user has no active subscription
  if (!data?.currentUser?.customer_portal_session?.url) {
    return null
  }

  return (
    <Button
      block
      style="modal"
      variant="underline"
      href={data.currentUser.customer_portal_session.url}
      target="_blank"
    >
      Manage Subscription
    </Button>
  )
}

export default ManageSubscriptionButton
