import Paragraphs from 'components/home/common/typography/Paragraphs'
import Pill, { PillProps } from 'components/home/Pill'

interface LoginAndSecurityItemProps {
  icon: React.ReactNode
  title: string
  subtitle?: string
  pillProps: PillProps
}

const LoginAndSecurityItem = ({
  icon,
  title,
  subtitle,
  pillProps,
}: LoginAndSecurityItemProps) => (
  <div className="flex items-center justify-between py-10">
    <div className="flex items-center gap-12">
      {icon}

      <div>
        <Paragraphs.LG className="text-grey-900">
          {title}
        </Paragraphs.LG>

        {subtitle && (
          <Paragraphs.SM className="text-grey-600">
            {subtitle}
          </Paragraphs.SM>
        )}
      </div>
    </div>

    <Pill {...pillProps}>
      {pillProps.children}
    </Pill>
  </div>
)

export default LoginAndSecurityItem
