import classNames from 'classnames'

function Error ({
  error,
  className = '',
}: {
  error?: string
  className?: string
}) {
  if (!error) {
    return null
  }

  return (
    <div className={classNames('w-full text-red text-14', className)}>
      {error}
    </div>
  )
}

export default Error
