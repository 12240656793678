import { Control, Controller, FieldError, FieldValues, Path, PathValue } from 'react-hook-form'
import Switch from 'components/form/Switch'
import Label from 'components/form/Label'
import Field from 'components/form/Field'

export default function ControlledSwitch<T extends FieldValues> ({
  name,
  control,
  label,
  propagate = true,
  defaultValue,
  error,
}: {
  name: Path<T>
  control: Control<T>
  label?: string
  propagate: boolean
  defaultValue?: PathValue<T, Path<T>>
  error: FieldError | undefined
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Field
          className="flex items-center gap-5 w-max"
          error={error?.message}
        >
          {
            label
              ? <Label className="leading-[25px] !mb-0 text-12">{label}</Label>
              : null
          }

          <Switch
            checked={value}
            onChange={onChange}
            propagate={propagate}
          />
        </Field>
      )}
    />
  )
}
