import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { RestBooking } from 'data/types/booking'
import { RootState } from 'store'
import { RestSharedUserInvite, RestUserSmall } from 'data/types/user'
import classNames from 'classnames'
import { setSharedUsersAndInvites } from 'store/booking-shared-users'
import ProfileIconsButton from 'components/ProfileIconsButton'
import { ShareIcon } from 'components/icons'
import UserTripServiceShareModal from 'components/booking/trip-schedule/UserTripServiceShareModal'
import InvitedGuestModal from './bookings/shared/InvitedGuestsModal'

interface Props {
  booking: RestBooking
  showLabel?: boolean
  useRedux?: boolean
  size?: 'sm' | 'md'
  className?: {
    container?: string
  }
}

const BookingGuestsTrigger = ({
  booking,
  showLabel = true,
  useRedux = true,
  size = 'md',
  className,
}: Props) => {
  const dispatch = useDispatch()

  const { sharedUsers, sharedUserInvites } = useSelector((state: RootState) => ({
    sharedUsers: state.bookingSharedUsers.sharedUsers,
    sharedUserInvites: state.bookingSharedUsers.sharedUserInvites,
  }))

  const limitedGuests = useMemo(() => {
    let guests: (RestUserSmall | RestSharedUserInvite)[] = []

    if (useRedux) {
      guests = guests.concat(sharedUsers ?? [])
      guests = guests.concat(sharedUserInvites ?? [])
    } else {
      guests = guests.concat(booking.shared_users ?? [])
      guests = guests.concat(booking.shared_user_invites ?? [])
    }

    return guests

  }, [sharedUsers, sharedUserInvites, booking])

  const router = useRouter()

  const [isTripShareModalOpen, setIsTripShareModalOpen] = useState(false)
  const [isInviteGuestModalOpen, setIsInviteGuestModalOpen] = useState(false)

  useMemo(() => {
    if (router?.query?.pending === 'guest') {
      setIsInviteGuestModalOpen(true)
    }
  }, [router?.query?.pending])

  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!useRedux) {
      dispatch(setSharedUsersAndInvites({
        shared_users: booking.shared_users ?? [],
        shared_user_invites: booking.shared_user_invites ?? [],
      }))
    }

    event.preventDefault()
    if (limitedGuests.length === 0) {
      setIsTripShareModalOpen(true)
    } else {
      setIsInviteGuestModalOpen(true)
    }
  }

  return (
    <>
      <InvitedGuestModal
        booking={booking}
        isOpen={isInviteGuestModalOpen}
        onClose={() => setIsInviteGuestModalOpen(false)}
      />

      <UserTripServiceShareModal
        booking={booking}
        isOpen={isTripShareModalOpen}
        onClose={() => setIsTripShareModalOpen(false)}
      />

      <div className={classNames(
        'flex items-center gap-[11px]',
        className?.container,
      )}>
        <ProfileIconsButton
          users={limitedGuests.map((guest) => ({
            id: guest.id.toString(),
            first_name: guest.first_name,
            profile_image: 'profile_image' in guest
              ? { url: guest.profile_image }
              : null,
          }))}
          size={size}
          emptyIcon={<ShareIcon className="stroke-2 text-18" />}
          onClick={(e) => openModal(e)}
        />

        {showLabel && (
          <button
            onClick={(e) => openModal(e)}
            className="underline text-primary text-12 leading-20 hover:text-primary-active"
          >
            View All Guests
          </button>
        )}
      </div>
    </>
  )
}

export default BookingGuestsTrigger
