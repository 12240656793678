import classNames from 'classnames'
import Error from 'components/form/Error'
import Label from 'components/form/Label'
import { ClockIcon, CrossIcon } from 'components/icons'
import TimePicker, { TimePickerProps } from 'react-time-picker'

export interface TimeInputProps extends TimePickerProps {
  variant: 'admin' | 'customer' | 'booking-information'
  subVariant?: 'survey'
  error?: string
  label?: string
  surveyLabel?: string
}

const TimeInput = ({
  variant,
  subVariant,
  error,
  label,
  surveyLabel,
  ...props
}: TimeInputProps) => {
  return (
    <div className={classNames(
      'relative w-full',
      {
        'flex flex-col': subVariant === 'survey',
      },
    )}>
      {
        surveyLabel && (
          <Label variant="survey">{surveyLabel}</Label>
        )
      }

      <TimePicker
        shouldOpenClock={() => false}
        format="h:mm a"
        className={classNames(
          'w-full text-grey-800',
          {
          // Customer
            'input-customer h-[56px] rounded-16 ring focus-within:ring-opacity-50 pl-16 pr-10 bg-grey-100 ring-grey-100': variant === 'customer',
            'focus-within:ring-primary-fresh': variant === 'customer' && !error,

            // Admin
            'input-admin h-50 rounded-4 lg:rounded-8 ring lg:ring-2 px-10': variant === 'admin',
            'ring-input-border lg:ring-primary ring-opacity-100 lg:ring-opacity-8': variant === 'admin' && !error,

            // Booking Information
            'input-booking-information h-50 rounded-4 ring pl-16 pr-10': variant === 'booking-information',
            'ring-input-border ring-opacity-100': variant === 'booking-information' && !error,

            // Error
            'ring-red ring-opacity-50': error,

            // Label
            'time-picker-label': label,
          },
          {
            '!h-60': subVariant === 'survey',
          },
        )}
        clearIcon={props.value ? <CrossIcon className="text-20" /> : null}
        clockIcon={<ClockIcon className="w-24 text-primary-fresh" />}
        {...props}
      />

      {
        label && (
          <label className={classNames(
            'absolute flex items-center px-20 pointer-events-none text-12 text-grey-900',
            {
              'top-5': variant === 'admin',
              'top-10 text-opacity-50': variant === 'customer',
            },
          )}>
            {label}
          </label>
        )
      }

      <Error className="mt-10" error={error} />
    </div>
  )
}

export default TimeInput
