import Checkbox from 'components/form/Checkbox'
import ControlledCheckboxGroup from 'components/form/Home/ControlledCheckboxGroup'
import ControlledDateInput from 'components/form/Home/ControlledDateInput'
import ControlledNumberInput from 'components/form/Home/ControlledNumberInput'
import ControlledPillSelect from 'components/form/Home/ControlledPillSelect'
import ControlledPillSwiper from 'components/form/Home/ControlledPillSwiper'
import ControlledSelect, { Option as SelectOption } from 'components/form/Home/ControlledSelect'
import ControlledSwitch from 'components/form/ControlledSwitch'
import dynamic from 'next/dynamic'
import Error from 'components/form/Error'
import Field from 'components/form/Field'
import File from 'components/form/File'
import ImageUpload from 'components/form/ImageUpload'
import Input from 'components/form/Home/Input'
import Label from 'components/form/Home/Label'
import Number from 'components/form/Number'
import Price from 'components/form/Price'
import ProfileImageUpload from 'components/form/ProfileImageUpload'
import ServiceImageUpload from 'components/form/ServiceImageUpload'
import Textarea from 'components/form/Home/Textarea'
import Toggle from 'components/form/Home/Toggle'
import ControlledTimeInput from 'components/dates/ControlledTimeInput'

const ControlledCountryCodeSelect = dynamic(() => import('components/form/Home/ControlledCountryCodeSelect'), {
  ssr: false,
})

const ControlledCountrySelect = dynamic(() => import('components/form/Home/ControlledCountrySelect'), {
  ssr: false,
})

const MessageEditor = dynamic(() => import('components/form/Home/MessageEditor'), {
  ssr: false,
})

export default {
  Checkbox,
  ControlledCheckboxGroup,
  ControlledCountryCodeSelect,
  ControlledCountrySelect,
  ControlledDateInput,
  ControlledNumberInput,
  ControlledPillSelect,
  ControlledPillSwiper,
  ControlledSelect,
  ControlledSwitch,
  ControlledTimeInput,
  Error,
  Field,
  File,
  ImageUpload,
  Input,
  Label,
  MessageEditor,
  Number,
  Price,
  ProfileImageUpload,
  ServiceImageUpload,
  Textarea,
  Toggle,
}

export type {
  SelectOption,
}
