import { joinBy, pluraliseWithNumber } from '@ama-selections/ui'

const parseGuests = (
  adults: number | null = 0,
  children: number | null = 0,
  separator?: string,
) => {
  return joinBy([
    pluraliseWithNumber(adults, 'Adult', { nullable: true }),
    pluraliseWithNumber(children, 'Child', { nullable: true, pluralEnding: 'ren' }),
  ], separator)
}

export {
  parseGuests,
}

export {
  slugify,
  singularise,
  pluralise,
  pluraliseWithNumber,
  capitaliseFirstLetter,
  validateBoolean,
  formatEnum,
  joinBy,
} from '@ama-selections/ui'
