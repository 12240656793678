import { createSlice } from '@reduxjs/toolkit'
import { RestSharedUserInvite, RestUserSmall } from 'data/types/user'
import { RootState } from 'store'

export interface BookingSharedUsersState {
  sharedUsers: RestUserSmall[]
  sharedUserInvites: RestSharedUserInvite[]
}

const initialState: BookingSharedUsersState = {
  sharedUsers: [],
  sharedUserInvites: [],
}

export const bookingSharedUsersSlice = createSlice({
  name: 'bookingSharedUsers',
  initialState: initialState,
  reducers: {
    setSharedUsersAndInvites: (state, action: {
      type: string
      payload: {
        shared_users: RestUserSmall[]
        shared_user_invites: RestSharedUserInvite[]
      }
    }) => {
      state.sharedUsers = action.payload.shared_users
      state.sharedUserInvites = action.payload.shared_user_invites
    },
  },
})

export const { setSharedUsersAndInvites } = bookingSharedUsersSlice.actions

export const selectSharedUsers = (state: RootState) => state.bookingSharedUsers.sharedUsers
export const selectSharedUserInvites = (state: RootState) => state.bookingSharedUsers.sharedUserInvites

export default bookingSharedUsersSlice.reducer
