import React from 'react'
import { FieldError } from 'react-hook-form'
import ServiceRequestSlide from 'components/service-request-forms/components/ServiceRequestSlide'
import ServiceRequestQuestion from 'components/service-request-forms/components/ServiceRequestQuestion'
import ServiceRequestNextButton from 'components/service-request-forms/components/ServiceRequestNextButton'
import ServiceSurveyForm from 'components/form/ServiceSurveyForm'
import { CarRentalError, SurveyFormProps } from 'data/types/service_surveys'
import { QuestionText } from 'data/helpers/survey/surveys/car-rental'

const Slide2 = ({
  questionStartIndex,
  position,
  fetching,
  isSlideActive,
  control,
  formState: { errors },
  setValue,
  watch,
  clearErrors,
  booking,
}: SurveyFormProps) => {
  return (
    <ServiceRequestSlide
      slideNumber={2}
      position={position}
      isSlideActive={isSlideActive}
      setValue={setValue}
      clearErrors={clearErrors}
    >
      <ServiceRequestQuestion
        questionNumber={questionStartIndex}
        question={QuestionText(2)}
      />
      <ServiceSurveyForm.ControlledDatePicker
        name="question2.start_date"
        label="Start Date*"
        control={control}
        error={((errors as CarRentalError)?.question2 as unknown as {start_date: FieldError})?.start_date}
        placeholder="Choose date"
        disabled={!isSlideActive}
        dayPickerProps={{
          initialMonth: new Date(booking?.arrival) > new Date()
            ? new Date(booking?.arrival)
            : new Date(),
          disabledDays: [
            {
              before: new Date(booking?.arrival),
              after: new Date(booking?.departure),
            },
            {
              before: new Date(),
              after: new Date(watch('question4.end_date')),
            },
          ],
        }}
      />
      <ServiceRequestQuestion
        questionNumber={questionStartIndex + 1}
        question={QuestionText(3)}
      />
      <ServiceSurveyForm.Group>
        <ServiceSurveyForm.ControlledTimeInput
          name="question3.start_time"
          control={control}
          surveyLabel="Start Time*"
          error={((errors as CarRentalError)?.question3 as unknown as {start_time: FieldError})?.start_time}
          disabled={!isSlideActive}
          variant="customer"
          subVariant="survey"
        />
      </ServiceSurveyForm.Group>
      <ServiceRequestNextButton
        isLoading={fetching}
        disabled={!isSlideActive}
      />
    </ServiceRequestSlide>
  )
}

export default Slide2
