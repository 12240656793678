import { graphql } from 'gql'
import { useMutation } from 'urql'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import ConfirmPublicModal from 'components/modals/ConfirmPublicModal'
import useErrorHandlerGraphQl from 'data/hooks/useErrorHandlerGraphQl'

interface DeleteCustomEventModalProps {
  children: (
    props: {
      isLoading: boolean
      setIsOpen: (value: boolean) => void
      isOpen: boolean
    }
  ) => React.ReactNode
  customEventId?: string
  onSuccess?: () => void
}

const DELETE_CUSTOM_EVENT_MUTATION = graphql(`
  mutation DeleteCustomEvent($id: ID!) {
    deleteCustomEvent(id: $id) {
      id
      booking {
        id
      }
    }
  }
`)

const DeleteCustomEventModal = ({
  children,
  customEventId,
  onSuccess,
}: DeleteCustomEventModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = useQueryClient()
  const handleError = useErrorHandlerGraphQl()

  const [{ fetching }, deleteCustomEvent] = useMutation(DELETE_CUSTOM_EVENT_MUTATION)

  const handleDelete = async () => {
    if (!customEventId) { return }

    const result = await deleteCustomEvent({
      id: customEventId,
    })

    if (result.error) {
      handleError(result.error)
    } else {
      if (result.data?.deleteCustomEvent?.booking?.id) {
        queryClient.invalidateQueries(['tripScheduleGroup', Number(result.data.deleteCustomEvent.booking.id)])
      }
      setIsOpen(false)
      onSuccess?.()
    }
  }

  return (
    <>
      {
        children({
          isLoading: fetching,
          setIsOpen,
          isOpen,
        })
      }

      <ConfirmPublicModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Delete Event"
        message="Are you sure you want to delete your event?"
        action={handleDelete}
        isLoading={fetching}
      />
    </>
  )
}

export default DeleteCustomEventModal
