import PublicMobileModal from 'components/PublicMobileModal'
import Button, { StickyFooter } from 'components/home/common/Button'
import { useMutation } from 'urql'
import { graphql } from 'gql'
import { CustomEventDayInput, CustomEventInput, UserTripServiceQuery } from 'gql/graphql'
import { ModalProps } from 'data/types/modal'
import { RestBooking } from 'data/types/booking'
import ControlledDatePickerModal from 'components/dates/ControlledDatePickerModal'
import { useForm } from 'react-hook-form'
import { asDate, formatDefault, getYesterday } from '@ama-selections/ui/src/helpers/dates'
import SearchFilter from 'components/searchpage/SearchFilter'
import Form from 'components/form/Home'
import { useEffect, useState } from 'react'
import { parseGuests } from 'data/helpers/strings'
import { formatTimeRange, toTimeString } from 'data/helpers/time'
import { truncate } from 'lodash'
import useErrorHandlerGraphQl from 'data/hooks/useErrorHandlerGraphQl'
import { useQueryClient } from 'react-query'
import classNames from 'classnames'
import DeleteCustomEventModal from 'components/booking/trip-schedule/DeleteCustomEventModal'

interface UserCustomEventModalProps extends ModalProps {
  booking: Pick<RestBooking, 'id' | 'arrival' | 'departure'>
  tripService?: UserTripServiceQuery['tripScheduleService']
  initialDate?: Date
}

type CustomEventFormData = Omit<CustomEventInput, 'days'> & {
  days: CustomEventDayInput[]
}

const UserCustomEventModal = ({
  isOpen,
  onClose,
  booking,
  tripService,
  initialDate,
}: UserCustomEventModalProps) => {
  const queryClient = useQueryClient()
  const handleError = useErrorHandlerGraphQl()

  useEffect(() => {

  }, [tripService])

  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CustomEventFormData>()

  useEffect(() => {
    if (isOpen) {
      if (tripService) {
        reset({
          title: tripService.group.title,
          subtitle: tripService.group.subtitle,
          adults: tripService.group.adults,
          children: tripService.group.children,

          days: [{
            date: tripService.date,
            start_time: toTimeString(tripService.start_time),
            end_time: toTimeString(tripService.end_time),
            service_details: tripService.service_details,
          }],
        })
      } else {
        reset(
          initialDate
            ? {
              days: [{
                date: formatDefault(initialDate),
              }],
            }
            : undefined,
        )
      }
    }
  }, [isOpen, tripService])

  const [isTimeOpen, setIsTimeOpen] = useState(false)
  const [isGuestsOpen, setIsGuestsOpen] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  const [{ fetching: createCustomEventFetching }, createCustomEvent] = useMutation(graphql(`
      mutation CreateCustomEvent($bookingId: ID!, $input: CustomEventInput!) {
        createCustomEvent(booking_id: $bookingId, input: $input) {
          id
        }
      }
    `))

  const [{ fetching: updateCustomEventFetching }, updateCustomEvent] = useMutation(graphql(`
      mutation UpdateCustomEvent($id: ID!, $input: CustomEventInput!) {
        updateCustomEvent(id: $id, input: $input) {
          id
        }
      }
    `))

  const onSubmit = async (data: CustomEventFormData) => {
    const result = tripService
      ? await updateCustomEvent({
        id: tripService.group.id,
        input: {
          ...data,
        },
      })
      : await createCustomEvent({
        bookingId: booking.id.toString(),
        input: {
          ...data,
        },
      })

    if (result.error) {
      handleError(result.error, setError)
    } else {
      queryClient.invalidateQueries(['tripScheduleGroup', booking.id])
      onClose()
    }
  }

  const closeModal = () => {
    reset()
    setIsTimeOpen(false)
    setIsGuestsOpen(false)
    setIsDetailsOpen(false)
    onClose()
  }

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={closeModal}
      className={{
        panel: '!max-h-screen-safe h-full',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full gap-10 px-24 overflow-y-auto">
        {/* Title input */}
        <div className="sticky top-0 z-20 flex flex-col gap-5 px-24 pt-20 pb-10 -mx-24 bg-white border-b-2 border-grey-100">
          <label
            htmlFor="title"
            className="font-bold text-grey-900 text-14 leading-28"
          >
            Event Title
          </label>

          <input
            id="title"
            {...register('title')}
            className={classNames(
              'leading-30 text-16 focus:outline-none text-grey-900 placeholder-grey-300',
              {
                'border-red-500': errors.title,
                'font-bold': watch('title'),
              },
            )}
            placeholder="Type here..."
          />

          {errors.title && (
            <Form.Error error={errors.title.message} />
          )}
        </div>

        {/* Date picker */}
        <div>
          <ControlledDatePickerModal
            datePickerProps={{
              name: 'days.0.date',
              control: control,
              disabledDates: (booking.arrival && booking.departure)
                ? ([
                  { before: asDate(booking.arrival)! },
                  { after: asDate(booking.departure)! },
                ])
                : undefined,
              initialMonth: asDate(getYesterday(booking.arrival)),
              minDate: asDate(getYesterday(booking.arrival)),
            }}
            selectedDate={asDate(watch('days.0.date'))}
            onClear={() => setValue('days.0.date', undefined)}
            error={errors.days?.[0]?.date?.message}
          />
        </div>

        {/* Time picker */}
        <SearchFilter
          label="Time"
          placeholder="When?"
          open={isTimeOpen}
          onClick={() => setIsTimeOpen(!isTimeOpen)}
          className={{ childrenContainer: 'gap-10 flex flex-col' }}
          value={formatTimeRange(watch('days.0.start_time'), watch('days.0.end_time'))}
        >
          <Form.ControlledTimeInput
            name="days.0.start_time"
            control={control}
            variant="customer"
            label="Start time"
            error={errors.days?.[0]?.start_time}
          />

          <Form.ControlledTimeInput
            name="days.0.end_time"
            control={control}
            variant="customer"
            label="End time"
            error={errors.days?.[0]?.end_time}
          />
        </SearchFilter>

        {/* Guests input */}
        <SearchFilter
          open={isGuestsOpen}
          placeholder="Who's coming?"
          onClick={() => setIsGuestsOpen(!isGuestsOpen)}
          label="Guests"
          value={parseGuests(watch('adults'), watch('children'))}
        >
          <Form.ControlledNumberInput
            placeholder="0"
            label="Adults"
            sublabel="18 or older"
            control={control}
            name="adults"
            error={errors.adults}
            className={{ container: 'px-8 pt-8 pb-12' }}
          />

          <Form.ControlledNumberInput
            name="children"
            control={control}
            placeholder="0"
            label="Children"
            sublabel="Under 18"
            error={errors.children}
            className={{ container: 'px-8 pt-8 pb-12' }}
          />
        </SearchFilter>

        {/* Details section */}
        <SearchFilter
          open={isDetailsOpen}
          placeholder="Add a description"
          onClick={() => setIsDetailsOpen(!isDetailsOpen)}
          label="Details"
          className={{ childrenContainer: 'gap-10 flex flex-col' }}
          value={(watch('subtitle') || watch('days.0.service_details'))
            ? truncate(watch('subtitle') || watch('days.0.service_details') || '', { length: 20 })
            : undefined
          }
        >
          <Form.Input
            {...register('subtitle')}
            variant="grey"
            placeholder="Subtitle"
            error={errors.subtitle?.message}
          />

          <Form.Textarea
            {...register('days.0.service_details')}
            placeholder="Description"
            error={errors.days?.[0]?.service_details}
          />
        </SearchFilter>

        <StickyFooter>
          <Button
            block
            style="modal"
            variant="primary"
            type="submit"
            isLoading={updateCustomEventFetching || createCustomEventFetching}
          >
            Save
          </Button>

          <DeleteCustomEventModal
            customEventId={tripService?.group.id}
            onSuccess={closeModal}
          >
            {({ setIsOpen }) => (
              <Button
                block
                style="modal"
                variant="underline"
                type="button"
                onClick={() => tripService ? setIsOpen(true) : closeModal()}
                className={{ button: 'mt-8' }}
              >
                {tripService ? 'Delete' : 'Discard'}
              </Button>
            )}
          </DeleteCustomEventModal>
        </StickyFooter>
      </form>
    </PublicMobileModal>
  )
}

export default UserCustomEventModal
