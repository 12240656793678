import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form'
import TimeInput, { TimeInputProps } from './TimeInput'

interface ControlledTimeInputProps<T extends FieldValues> extends Omit<TimeInputProps, 'onChange' | 'value' | 'error'> {
  name: Path<T>
  control: Control<T>
  error?: FieldError
}

export default function ControlledTimeInput<T extends FieldValues> ({
  name,
  control,
  error,
  ...props
}: ControlledTimeInputProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TimeInput
          value={value}
          onChange={onChange}
          error={error?.message}
          {...props}
        />
      )}
    />
  )
}
