import classNames from 'classnames'
import { CheckCircleIcon, ClockIcon, ExclaimCircleIcon, PendingIcon } from 'components/icons'
import { TripScheduleStatus } from 'data/enums/trip-schedule/trip-schedule-status'
import { PaymentStatus, TripScheduleGroup, TripScheduleServiceStatusType, TripScheduleServiceType } from 'gql/graphql'

const checkInCheckOutTitleOptions = [
  'Check In',
  'Check Out',
]

const hasPayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Due || status === PaymentStatus.Pending)
}

const hasDuePayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Due)
}

const hasPendingPayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Pending)
}

const hasCompletedPayments = (statuses: TripScheduleGroup['payment_statuses']) => {
  return statuses?.some((status) => status === PaymentStatus.Completed)
}

const isDraft = (status: TripScheduleServiceStatusType) => {
  return status === TripScheduleServiceStatusType.Draft || status === TripScheduleServiceStatusType.ConciergeDraft
}

const isPending = (status: TripScheduleServiceStatusType) => {
  return status === TripScheduleServiceStatusType.PendingApproval || status === TripScheduleServiceStatusType.PendingPayment
}

const getTripScheduleServiceStatusType = (status: TripScheduleStatus) => {
  switch (status) {
    case TripScheduleStatus.DRAFT:
      return TripScheduleServiceStatusType.Draft
    case TripScheduleStatus.CONCIERGE_DRAFT:
      return TripScheduleServiceStatusType.ConciergeDraft
    case TripScheduleStatus.PENDING_APPROVAL:
      return TripScheduleServiceStatusType.PendingApproval
    case TripScheduleStatus.PENDING_PAYMENT:
      return TripScheduleServiceStatusType.PendingPayment
    case TripScheduleStatus.CANCELLED:
      return TripScheduleServiceStatusType.Cancelled
    case TripScheduleStatus.CONFIRMED:
      return TripScheduleServiceStatusType.Confirmed
  }
}

const getTripScheduleServiceType = (type: string) => {
  switch (type) {
    case 'custom_event':
      return TripScheduleServiceType.CustomEvent
    case 'event':
      return TripScheduleServiceType.Event
    case 'service':
      return TripScheduleServiceType.Service
  }

  throw new Error(`Unknown trip schedule service type: ${type}`)
}

const getTripServiceStatusIcon = (
  status: TripScheduleServiceStatusType | 'check-in/out',
  className?: string,
) => {
  switch (status) {
    case 'check-in/out':
      return <ClockIcon className={className} />
    case TripScheduleServiceStatusType.Confirmed:
      return <CheckCircleIcon className={classNames(
        'text-primary-fresh',
        className,
      )} />
    case TripScheduleServiceStatusType.PendingApproval:
    case TripScheduleServiceStatusType.PendingPayment:
    case TripScheduleServiceStatusType.Cancelled:
      return <ExclaimCircleIcon className={classNames(
        'text-red-800',
        className,
      )} />
    case TripScheduleServiceStatusType.Draft:
      return <PendingIcon className={className} />
    case TripScheduleServiceStatusType.ConciergeDraft:
    default:
      return null
  }
}

export {
  checkInCheckOutTitleOptions,
  // Payments Page
  hasPayments,
  hasDuePayments,
  hasPendingPayments,
  hasCompletedPayments,

  // Trip Schedule Status
  getTripScheduleServiceStatusType,
  getTripScheduleServiceType,
  getTripServiceStatusIcon,
  isDraft,
  isPending,
}
