import classNames from 'classnames'
import { TripScheduleServiceStatusType, TripScheduleServiceType } from 'gql/graphql'
import Image from 'components/Image'

export interface UserTripServiceImageProps {
  image: {
    src: string
    alt: string
  }
  type: TripScheduleServiceType
  status: TripScheduleServiceStatusType
  className?: {
    container?: string
    image?: string
    color?: string
  }
}

const UserTripServiceImage = ({
  image,
  type,
  status,
  className,
}: UserTripServiceImageProps) => {
  return (
    <div className={classNames(className?.container, 'relative')}>
      {
        image.src
          ? (
            <Image
              src={image.src}
              alt={image.alt}
              className={classNames(className?.image, 'object-cover')}
              layout="fill"
            />
          )
          : (
            <div className={classNames(
              className?.color,
              'w-full h-full rounded',
              type === TripScheduleServiceType.CustomEvent
                ? 'bg-slate-500'
                : {
                  'bg-primary-fresh': true,
                  'bg-opacity-70': status === TripScheduleServiceStatusType.Confirmed,
                  'bg-opacity-40': status !== TripScheduleServiceStatusType.Confirmed,
                },
            )} />
          )
      }
    </div>
  )
}

export default UserTripServiceImage
