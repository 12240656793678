import { getLoadedImagesArray, getNumberArray } from '@ama-selections/ui'
import { useEffect, useState } from 'react'

interface useCarouselLoadedImagesProps {
  imagesEitherSide: number
  imagesLength: number
  isLoop?: boolean
}

export const useCarouselLoadedImages = ({
  imagesEitherSide,
  imagesLength,
  isLoop = false,
}: useCarouselLoadedImagesProps) => {
  const numberArray = getNumberArray(imagesLength)
  const [loadedImages, setLoadedImages] = useState<number[]>([])

  useEffect(() => {
    setLoadedImages([
      ...numberArray.slice(0, imagesEitherSide + 1),
      ...(isLoop ? numberArray.slice(-imagesEitherSide) : []),
    ])
  }, [imagesLength, imagesEitherSide, isLoop])

  const onRealIndexChange = (realIndex: number) => {
    if (loadedImages.length !== imagesLength) {
      setLoadedImages(getLoadedImagesArray(
        realIndex,
        imagesEitherSide,
        imagesLength,
        numberArray,
        loadedImages,
      ))
    }
  }

  const isImageLoaded = (index: number) => {
    if (imagesLength <= imagesEitherSide + 1) {
      return true
    }

    return loadedImages.includes(index)
  }

  return {
    loadedImages,
    onRealIndexChange,
    isImageLoaded,
  }
}
