import React, { useEffect, useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { groupBy } from 'data/helpers/array'
import { formatDefault } from 'data/helpers/dates'
import { ArrowLeftIcon } from 'components/icons'
import CalendarViewItem from 'components/trip-schedule/CalendarViewItem'
import { useDispatch } from 'react-redux'
import { setSelectedService } from 'store/trip-schedule'
import { uniq } from 'lodash'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import BookNewTripServiceTrigger from 'components/trip-schedule/BookNewTripServiceTrigger'
import { RestBooking } from 'data/types/booking'
import { asDate } from '@ama-selections/ui/src/helpers/dates'
import useAuth from 'data/hooks/User/useAuth'
import { hasFullAccess } from 'data/helpers/booking'

interface CalendarViewTabProps {
  tripServices: TripScheduleService[]
  booking: RestBooking
}

const CalendarViewTab = ({
  tripServices,
  booking,
}: CalendarViewTabProps) => {
  const { user } = useAuth()
  const dispatch = useDispatch()

  const [days, setDays] = useState<Dayjs[]>([])
  const [selectedMonth, setSelectedMonth] = useState<number>(0)

  const months = uniq(days.map(day => day.format('MMMM YYYY')))

  useEffect(() => {
    const arrival = dayjs(booking.arrival)
    const departure = dayjs(booking.departure)
    const diff = departure.diff(arrival, 'days')
    const items: Dayjs[] = []

    for (let i = 0; i <= diff; i++) {
      items[i] = dayjs(arrival).add(i, 'day')
    }

    setDays(items)
  }, [])

  const groupedTripServices: Record<string, TripScheduleService[]> = useMemo(
    () => groupBy(
      tripServices.sort((a, b) => a.start_time?.localeCompare(b.start_time)),
      'date',
    ),
    [tripServices],
  )

  const buttonClassNames = 'lg:bg-white p-[14px] rounded-full lg:shadow-circle-button disabled:opacity-50'

  return (
    <div className="flex flex-col w-full h-full overflow-y-hidden bg-grey-100">
      <div className="flex items-center top-0 z-10 justify-between w-full md:sticky lg:px-[9px] lg:py-[13px] bg-grey-150 max-h-[68px]">
        <button
          className={buttonClassNames}
          onClick={() => setSelectedMonth(selectedMonth - 1)}
          disabled={selectedMonth === 0}
          type="button"
        >
          <ArrowLeftIcon className="h-14 w-14 stroke-[2]" />
        </button>

        <p className="font-serif font-bold text-18">
          {months[selectedMonth]}
        </p>

        <button
          className={buttonClassNames}
          onClick={() => setSelectedMonth(selectedMonth + 1)}
          disabled={selectedMonth + 1 === months.length}
          type="button"
        >
          <ArrowLeftIcon className="h-14 w-14 stroke-[2] rotate-180" />
        </button>
      </div>

      <div className="flex flex-wrap w-full overflow-y-auto">
        {
          (days ?? [])
            .filter(day => dayjs(day).format('MMMM YYYY') === months[selectedMonth])
            .map((day, index) => (
              <div
                key={index}
                className="flex flex-col min-h-[340px] sm:h-auto h-max bg-white border-t border-r border-grey-225 basis-full sm:basis-1/2 md:basis-1/3 lg:basis-1/2 xl:basis-1/4"
              >
                <div className="sticky top-0 z-10 flex justify-center py-16 bg-white border-b text-grey-225">
                  <p className="text-14 leading-23 text-grey-800">
                    <strong>
                      {day.format('ddd')},&nbsp;
                    </strong>
                    {day.format('MMM D')}
                  </p>
                </div>

                <div className="flex flex-col justify-center flex-1 gap-8 p-10">
                  {
                    groupedTripServices
                      ?.[formatDefault(day)!]
                      ?.map((service, index) => (
                        <CalendarViewItem
                          key={index}
                          service={service}
                          booking={booking}
                          action={() => dispatch(setSelectedService(service?.id))}
                        />
                      ))
                  }

                  <BookNewTripServiceTrigger
                    booking={booking}
                    date={asDate(day)}
                  >
                    {({ open }) => (
                      <button
                        type="button"
                        className="w-full h-full py-30 text-grey-500 text-12"
                        onClick={open}
                      >
                        Request a service
                        {
                          hasFullAccess(user, booking)
                            ? <><br />or add your own event</>
                            : null
                        }
                      </button>
                    )}
                  </BookNewTripServiceTrigger>
                </div>
              </div>
            ))
        }
      </div>
    </div>
  )
}

export default CalendarViewTab
