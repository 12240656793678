import { joinBy } from '@ama-selections/ui'
import { TripSchedule } from 'data/enums/trip-schedule/trip-schedule-type'
import { RestBooking } from 'data/types/booking'
import { RestProperty } from 'data/types/property'
import { RestUser } from 'data/types/user'

const formatAddress = (property: Pick<RestProperty, 'address'>) => {
  if (!property?.address) {
    return 'No Address'
  }

  const { address } = property

  return joinBy(
    [
      address.line_1,
      address.city,
      address.country,
    ],
    ', ',
  )
}

const getTripScheduleLink = (
  bookingId: string | number,
  serviceId: string | number,
  serviceType: TripSchedule | string,
) => {
  return `/dashboard/bookings/${bookingId}/trip-schedule?${serviceType === TripSchedule.GROUP
    ? `groupId=${serviceId}`
    : `serviceId=${serviceId}`}`
}

const getOutOfOfficeText = (booking: RestBooking) => {
  if (booking?.concierge_holiday_date) {
    if (booking?.temporary_concierge) {
      return `(Set, temporary concierge: ${booking?.temporary_concierge?.first_name})`
    } else {
      return '(Set, no concierge)'
    }
  }

  if (booking?.digital_concierge?.out_of_office_dates?.some((date) => date?.is_active)) {
    return '(On)'
  }

  return ''
}

const getBookingTotal = (booking: RestBooking) => {
  if (!booking) {
    return 0
  }

  return booking.total_rent
    + (booking.total_additional_costs ?? 0)
    + (booking.total_services ?? 0)
    + (booking.damage_deposit ?? 0)
    - (booking.discount ?? 0)
    - (booking.membership_credit ?? 0)
}

const hasFullAccess = (
  user: RestUser | null,
  booking: RestBooking,
) => {
  if (!user) {
    return false
  }

  if (user.id === booking.client?.id) {
    return true
  }

  if (booking.shared_users?.some((sharedUser) => sharedUser.id === user.id && sharedUser.has_full_access)) {
    return true
  }

  return false
}

export {
  formatAddress,
  getTripScheduleLink,
  getOutOfOfficeText,
  getBookingTotal,
  hasFullAccess,
}
