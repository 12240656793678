import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import ApiClient from 'data/api/api_client'
import { useNotifier } from 'react-headless-notifier'
import { setSharedUsersAndInvites } from 'store/booking-shared-users'
import Notification from 'components/Notification'
import { RestBooking } from 'data/types/booking'
import { RestUser } from 'data/types/user'
import PublicMobileModal from 'components/PublicMobileModal'
import Button from 'components/home/common/Button'
import Headers from 'components/home/common/typography/Headers'
import ProfileImage from 'components/ProfileImage'
import PrimaryGuestDisclaimer from './PrimaryGuestDisclaimer'

interface PrimaryGuestModalProps {
  booking: RestBooking
  user: RestUser | null
  isOpen: boolean
  onClose: () => void
}

const PrimaryGuestModal = ({
  booking,
  user,
  isOpen,
  onClose,
}: PrimaryGuestModalProps) => {
  const dispatch = useDispatch()
  const { notify } = useNotifier()

  const { mutate: shareFullAccesss, isLoading } = useMutation(
    () => ApiClient.bookings.shareFullAccess(booking.id, user?.id),
    {
      onSuccess: (response) => {
        dispatch(setSharedUsersAndInvites(response))
        notify(
          <Notification type="success" message="Full access granted successfully" />,
        )
        onClose?.()
      },
      onError: () => {
        notify(
          <Notification message="There was an issue granting this user full access, please try again" type="error" />,
        )
      },
    },
  )

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      backIcon={true}
      className={{
        panel: 'flex flex-col px-24 pb-safe-offset-30',
      }}
    >
      <Headers.H1 className="pt-20 font-serif font-bold text-center mb-25">Primary Guest</Headers.H1>

      {user && (
        <ProfileImage
          className="mx-auto h-100 w-100 mb-30"
          user={user}
        />
      )}

      <Headers.H4 className="!font-sans font-medium !tracking-default text-center !leading-24 mb-35 text-grey-800">
        Are you sure you would like to make {user?.first_name} {user?.last_name} the Primary Guest?
      </Headers.H4>

      <PrimaryGuestDisclaimer>
        <u>Only one of your guests can be given full access.</u>
      </PrimaryGuestDisclaimer>

      <div className="flex justify-center">
        <Button
          isLoading={isLoading}
          className={{
            button: 'max-w-[326px]',
          }}
          style="modal"
          onClick={() => shareFullAccesss()}
        >
          CHOOSE AS PRIMARY GUEST
        </Button>
      </div>

    </PublicMobileModal>
  )
}

export default PrimaryGuestModal
