import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import DatePicker, { DatePickerProps } from './DatePicker'

export interface ControlledDatePickerProps<T extends FieldValues> extends Omit<DatePickerProps, 'value' | 'onChange'> {
  name: Path<T>
  control: Control<T>
}

export default function ControlledDatePicker<T extends FieldValues> ({
  name,
  control,
  ...props
}: ControlledDatePickerProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  )
}
