import PublicMobileModalDrawer from 'components/PublicMobileModalDrawer'
import useAuth from 'data/hooks/User/useAuth'
import { ModalProps } from 'data/types/modal'
import IconButton from 'components/IconButton'
import { ArrowLeftIcon, FacebookIcon, GoogleStyledIcon, ProfilePenIcon, AppleIcon, LoadingIcon, CheckIcon } from 'components/icons'
import GuestDisclaimer from 'components/modals/bookings/shared/Disclaimer'
import { useMutation, useQuery } from 'react-query'
import ApiClient from 'data/api/api_client'
import queryCache from 'data/queryCache'
import { NavSection } from 'components/user/profile/NavSection'
import { Divider } from 'components/user/profile/ProfileModalNavigation'
import LoginAndSecurityItem from '../LoginAndSecurityItem'
import { useMemo, useState, useEffect } from 'react'
import { PillProps } from 'components/home/Pill'
import { formatRelativeTime } from 'data/helpers/dates'

interface LoginAndSecurityModalProps extends ModalProps { }

type SocialProviderId = 'facebook' | 'google' | 'apple';

const SocialProviders: Array<{
  id: SocialProviderId;
  title: string;
  icon: JSX.Element;
}> = [
  {
    id: 'facebook',
    title: 'Facebook',
    icon: <FacebookIcon className="text-22 text-[#1877F2] group-hover:scale-110 transition-transform" />,
  },
  {
    id: 'google',
    title: 'Google',
    icon: <GoogleStyledIcon className="text-22" />,
  },
  {
    id: 'apple',
    title: 'Apple',
    icon: <AppleIcon className="text-22" />,
  },
]

const LoginAndSecurityModal = ({
  isOpen,
  onClose,
}: LoginAndSecurityModalProps) => {
  const { user } = useAuth()

  useEffect(() => {
    if (isOpen) {
      setPasswordResetState(null)
    }
  }, [isOpen])

  const { data: socialLinks } = useQuery(
    queryCache.socialLinks,
    () => ApiClient.auth.getSocialLinks(),
  )

  const [passwordResetState, setPasswordResetState] = useState<null | 'success' | 'error'>(null)

  const resetMutation = useMutation(
    () => ApiClient.auth.triggerPasswordReset(user?.email),
    {
      onSuccess: () => setPasswordResetState('success'),
      onError: () => setPasswordResetState('error'),
    },
  )

  const passwordResetStateProps: Pick<PillProps, 'children' | 'suffix' | 'className'> = useMemo(() => {
    if (resetMutation.isLoading) {
      return {
        children: 'Sending',
        suffix: <LoadingIcon />,
      }
    }

    if (passwordResetState === 'success') {
      return {
        children: 'Sent',
        suffix: <CheckIcon className="text-22" />,
      }
    }

    if (passwordResetState === 'error') {
      return {
        children: 'Error',
        className: 'bg-red-50 text-red-800',
      }
    }

    return {
      children: user?.login_methods.email ? 'Update' : 'Add',
    }
  }, [resetMutation.isLoading, passwordResetState])

  return (
    <PublicMobileModalDrawer
      isOpen={isOpen}
      onClose={onClose}
      position="right"
      hasFocusTrap
      title="Login & security"
      className={{
        panel: 'flex flex-col pb-safe-offset-10',
        closeButton: '!bg-transparent z-10',
        title: 'z-10',
      }}
    >
      <IconButton
        className="absolute z-10 !bg-transparent top-safe-offset-20 left-20"
        onClick={onClose}
        variant="modal-close"
      >
        <ArrowLeftIcon className="stroke-2" />
      </IconButton>

      <div className="z-10 mt-40">
        <NavSection heading="LOGIN">
          <LoginAndSecurityItem
            icon={<ProfilePenIcon className="text-22" />}
            title="Password"
            subtitle={user?.login_methods.password_last_updated_at
              ? `Last updated ${formatRelativeTime(user.login_methods.password_last_updated_at)}`
              : undefined}
            pillProps={{
              variant: 'grey',
              size: '8',
              onClick: () => resetMutation.mutate(),
              ...passwordResetStateProps,
            }}
          />

          <div className="flex justify-end text-right text-12 leading-16">
            {
              passwordResetState === 'error' && (
                <p className="text-right text-red-500 text-12">
                  Please try again
                </p>
              )
            }

            {
              passwordResetState === 'success' && (
                <p className="text-right text-grey-900 text-12">
                  Please check your email for a link to reset your password
                </p>
              )
            }
          </div>
        </NavSection>

        <Divider className="!mt-20 !mb-30" />

        {socialLinks && (
          <NavSection heading="SOCIAL ACCOUNTS">
            {SocialProviders.map((provider) => (
              <LoginAndSecurityItem
                key={provider.id}
                icon={provider.icon}
                title={provider.title}
                pillProps={{
                  ...(user?.login_methods[provider.id]
                    ? {}
                    : { href: socialLinks[provider.id] }
                  ) as { href: string } | { href?: never },
                  variant: user?.login_methods[provider.id] ? 'primary' : 'grey',
                  size: '8',
                  children: user?.login_methods[provider.id] ? 'Connected' : 'Connect',
                }}
              />
            ))}
          </NavSection>
        )}

        <GuestDisclaimer
          className={{
            container: 'mt-20 px-20 py-14 mx-20',
            text: 'text-center',
          }}
        >
          Connect your social accounts to enable easier login and account recovery options.
        </GuestDisclaimer>
      </div>
    </PublicMobileModalDrawer>
  )
}

export default LoginAndSecurityModal
