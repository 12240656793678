import React from 'react'
import classNames from 'classnames'
import AMALogo from 'assets/images/logo-no-tagline.jpg'
import { getProfileImage } from 'data/helpers/users'
import { InvitedWishlistUser, RestSharedUserInvite, RestUser, RestUserSmall } from 'data/types/user'
import Image from 'components/Image'
import { CheckCircleIcon } from 'components/icons'
import { Media, Recipient, User } from 'gql/graphql'

interface ProfileImagePropTypes {
  user: RestUser | Recipient | RestUserSmall | RestSharedUserInvite | User | InvitedWishlistUser | {
    id: User['id']
    name?: string
    first_name?: string
    profile_image?: Pick<Media, 'url'> | null
    email?: string
    identity_verified_at?: string
  }
  className?: string
  textClassName?: string
  variant?: 'customer' | 'admin'
  showVerified?: boolean
}

const ProfileImage = ({
  user,
  className,
  textClassName,
  variant = 'customer',
  showVerified,
}: ProfileImagePropTypes) => {
  const defaultClassNames = {
    text: classNames(
      'relative block',
      {
        'text-13 leading-22': variant === 'customer' && !textClassName,
        'text-blue-1000 font-sans tracking-1/4 font-bold': variant === 'customer',
        'text-13 text-white': variant === 'admin',
      },
      textClassName,
    ),
  }

  const getProfileImageComponent = () => {
    if (getProfileImage(user)) {
      return (
        <img
          src={getProfileImage(user)}
          className={classNames('object-cover rounded-full', className)}
        />
      )
    }

    if (user?.id) {
      return (
        <div className={classNames(
          'bg-grey-200 rounded-full flex items-center justify-center',
          className,
        )}>
          <span className={defaultClassNames.text}>
            {
              (user && 'first_name' in user && user.first_name)
                ? user.first_name.charAt(0)
                : 'name' in user && user?.name
                  ? user.name.charAt(0)
                  : user.email?.charAt(0)
            }
          </span>
        </div>
      )
    }

    return (
      <div className={classNames('relative', className)}>
        <Image
          src={AMALogo}
          layout="fill"
          className={classNames('object-scale-down rounded-full bg-white', className)}
        />
      </div>
    )
  }


  return (
    <div className="relative">
      {(user
        && 'identity_verified_at' in user
        && user?.identity_verified_at
        && showVerified)
        && (
          <CheckCircleIcon className="absolute border border-white rounded-full text-16 text-green -top-4 -right-5" />
        )}
      {getProfileImageComponent()}
    </div>
  )
}

export default ProfileImage
