import { CheckCircleIcon, ClockIcon, ExclaimCircleIcon, PendingIcon } from 'components/icons'
import { useDispatch } from 'react-redux'
import { setPendingServicesModalOpen, setSelectedServiceRequest } from 'store/trip-schedule'
import { isCheckInOrOut } from 'data/helpers/trip-schedule'
import { capitaliseFirstLetter, formatEnum } from 'data/helpers/strings'
import Pill from 'components/home/Pill'
import classNames from 'classnames'
import { Booking, TripScheduleGroup, TripScheduleServiceStatusType, TripScheduleServiceType } from 'gql/graphql'
import { isPending } from 'data/helpers/trip-schedule-group'

export interface UserTripServicePillProps {
  service: Pick<TripScheduleGroup, 'title' | 'status' | 'type'>
  serviceRequestId?: string | null
  booking: Pick<Booking, 'id'>
  bookingInformation: {
    arrival: boolean
    departure: boolean
  }
  onClose?: () => void
  type?: 'calendar' | 'summary'
}

const UserTripServicePill = ({
  service,
  serviceRequestId,
  booking,
  bookingInformation,
  onClose = () => { },
  type = 'summary',
}: UserTripServicePillProps) => {
  const dispatch = useDispatch()

  const iconSize = type === 'calendar'
    ? 'h-[11px] w-[11px]'
    : 'h-[13px] w-[13px]'

  const pillClasses = type === 'calendar'
    ? '!text-[11px] !py-[1px] px-8'
    : '!text-12'

  if (service.type === TripScheduleServiceType.CustomEvent) {
    return (
      <Pill
        size="10"
        className={pillClasses}
        variant="blank"
      >
        My Event
      </Pill>
    )
  }

  if (isCheckInOrOut(service.title)) {
    const isArrival = service?.title?.toLowerCase() === 'check in'
    const hasBookingInformation = isArrival ? bookingInformation.arrival : bookingInformation.departure
    const text = isArrival ? 'arrival' : 'departure'

    return (
      <Pill
        href={`/home/reservations/${booking?.id}/booking-information/${text}`}
        target="_blank"
        className={pillClasses}
        prefix={hasBookingInformation
          ? undefined
          : <ClockIcon className={iconSize} />
        }
        variant={hasBookingInformation ? 'grey' : 'danger'}
        size="10"
      >
        {hasBookingInformation ? 'View' : 'Complete'} {capitaliseFirstLetter(text)} Information
      </Pill>
    )
  }

  if (service.status === TripScheduleServiceStatusType.Confirmed) {
    return (
      <Pill
        variant="blank"
        size="10"
        className={pillClasses}
        prefix={<CheckCircleIcon className={classNames(
          'text-primary-fresh',
          iconSize,
        )} />}
      >
        {formatEnum(service.status)}
      </Pill>
    )
  }

  if (isPending(service.status) || service.status === TripScheduleServiceStatusType.Cancelled) {
    return (
      <Pill
        variant="danger"
        size="10"
        className={pillClasses}
        prefix={<ExclaimCircleIcon className={iconSize} />}
        {
          ...(service.status === TripScheduleServiceStatusType.Cancelled
            ? []
            : {
              onClick: (e) => {
                e.stopPropagation()
                dispatch(setPendingServicesModalOpen(booking.id))
                if (onClose) {
                  onClose()
                }
              },
            }
          )
        }
      >
        {formatEnum(service.status)}
      </Pill>
    )
  }

  if (service.status === TripScheduleServiceStatusType.ConciergeDraft && serviceRequestId) {
    return (
      <Pill
        size="10"
        variant="blank"
        className={pillClasses}
        onClick={(e) => {
          e.stopPropagation()
          dispatch(setSelectedServiceRequest(serviceRequestId))
          if (onClose) {
            onClose()
          }
        }}
      >
        View Service Request
      </Pill>
    )
  }

  if (service.status === TripScheduleServiceStatusType.Draft) {
    return (
      <Pill
        size="10"
        className={pillClasses}
        variant="blank"
        prefix={<PendingIcon />}
      >
        With Concierge
      </Pill>
    )
  }

  return null
}

export default UserTripServicePill
