import { formatTimeFromTime, joinBy } from '@ama-selections/ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

/**
 * Parses a time string and returns it in either 12-hour or 24-hour format
 * @param time - Time string in HH:mm format
 * @param format - If true, returns time in 12-hour format (h:mm A), otherwise returns 24-hour format (H:mm)
 */
const parseTime = (time: string, format: boolean = false): string => {
  const day = dayjs(time, 'HH:mm')

  if (format) {
    return day.format('h:mm A')
  }

  return day.format('H:mm')
}

const toTimeString = <T>(time: T, format: string = 'HH:mm'): T | string => {
  if (typeof time !== 'string') {
    return time
  }

  return dayjs(time, 'HH:mm').format(format)
}

const formatTimeRange = (
  start?: string | null,
  end?: string | null,
) => {
  if (!start && !end) {
    return undefined
  }

  return joinBy(
    [
      formatTimeFromTime(start),
      formatTimeFromTime(end),
    ],
    ' - ',
  )
}

export {
  parseTime,
  toTimeString,
  formatTimeRange,
}
