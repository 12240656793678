import { serialize } from 'object-to-formdata'
export default class TripScheduleApiClient {
  constructor (http) {
    this.http = http
  }

  /**
  * Add new trip schedule service to a booking
  *
  * @param {object} data
  * @returns {import('data/types/bookings/trip_schedule').TripScheduleService[]}
  */
  async getTripScheduleServices (bookingId, filters) {
    const result = await this.http.get(`/booking/${bookingId}/trip-schedule`, {
      params: filters,
    })
    return result.data.data
  }

  /**
  * Add new trip schedule service to a booking
  *
  * @param {object} data
  * @returns
  */
  async createTripScheduleService (data) {
    const bookingId = data.booking_id

    if (data.image || data.partner_invoice || data.uploaded_files) {
      data = serialize({ ...data }, {
        indices: true,
      })
    }

    const result = await this.http.post(`/booking/${bookingId}/trip-schedule`, data)
    return result.data.data
  }

  /**
  * Update a trip schedule service on a booking
  *
  * @param {object} data
  * @returns
  */
  async updateTripScheduleService (data, serviceId) {
    const bookingId = data.booking_id

    if (data.image || data.partner_invoice || data.uploaded_files) {
      data = serialize({ ...data }, {
        indices: true,
      })
    }

    const result = await this.http.patch(`/booking/${bookingId}/trip-schedule/${serviceId}`, data)
    return result.data.data
  }

  /**
  * Delete a trip schedule service on a booking
  *
  * @param {object} data
  * @returns {array}
  */
  async deleteTripScheduleService (bookingId, serviceId) {
    const result = await this.http.delete(`/booking/${bookingId}/trip-schedule/${serviceId}`)
    return result.data.data
  }

  /**
  * Update a trip schedule service on a booking
  *
  * @param {string | undefined} data.group_id
  * @returns {Promise<import('data/types/booking').RestBooking>}
  */
  async approveTripScheduleServices (bookingsId, data) {
    const result = await this.http.post(`/booking/${bookingsId}/trip-services/approve`, data)
    return result.data.data
  }

  /**
   * Get a trip schedule service on a booking, this is used for fetching the Survey Data
   *
   * @param {string} bookingId
   * @param {string} serviceId
   * @param {object} [filters]
   * @returns {Promise<import('data/types/bookings/trip_schedule').TripScheduleService}
   */
  async getTripScheduleService (bookingId, serviceId, filters = {}) {
    const result = await this.http.get(`/booking/${bookingId}/trip-schedule/${serviceId}`, {
      params: filters,
    })
    return result.data.data
  }
}
